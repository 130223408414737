import fetch from "./fetch";

const MMY01_01_URL = () => "/PC/WPC_MMY01_01";
const MMY01_011_URL = () => "/PC/WPC_MMY01_011";
const MMY01_04_URL = () => "/PC/WPC_MMY01_04"; // 작동 안함
const MMY01_041_URL = () => "/PC/WPC_MMY01_041";
const MMY01_09_URL = () => "/PC/WPC_MMY01_09";
const MMY01_091_URL = () => "/PC/WPC_MMY01_091";
const MMY01_10_URL = () => "/PC/WPC_MMY01_10";
const MMY01_101_URL = () => "/PC/WPC_MMY01_101";
const MMY01_102_URL = () => "/PC/WPC_MMY01_102";
const MMY01_11_URL = () => "/PC/WPC_MMY01_11";
const MMY01_12_URL = () => "/PC/WPC_MMY01_12";
const MMY01_122_URL = () => "/PC/WPC_MMY01_122";
const MMY01_13_URL = () => "/PC/WPC_MMY01_13";
const MMY01_121_URL = () => "/PC/WPC_MMY01_121";
const MMY01_20_URL = () => "/PC/WPC_MMY01_20";
const MMY01_22_URL = () => "/PC/WPC_MMY01_22";
const MMY01_201_URL = () => "/PC/WPC_MMY01_201";
const MMY01_421_URL = () => "/PC/WPC_MMY01_421";

export default {
  getMmy0101: fetch("GET", MMY01_01_URL),
  getMmy01011: fetch("GET", MMY01_011_URL),
  getMmy0104: fetch("GET", MMY01_04_URL),
  getMmy01041: fetch("GET", MMY01_041_URL),
  getMmy0109: fetch("GET", MMY01_09_URL),
  getMmy01091: fetch("GET", MMY01_091_URL),
  getMmy0110: fetch("GET", MMY01_10_URL),
  getMmy01101: fetch("GET", MMY01_101_URL),
  getMmy01102: fetch("GET", MMY01_102_URL),
  getMmy0111: fetch("GET", MMY01_11_URL),
  getMmy0112: fetch("GET", MMY01_12_URL),
  getMmy01122: fetch("GET", MMY01_122_URL),
  getMmy0113: fetch("GET", MMY01_13_URL),
  getMmy01121: fetch("GET", MMY01_121_URL),
  getMmy0120: fetch("GET", MMY01_20_URL),
  getMmy0122: fetch("GET", MMY01_22_URL),
  getMmy01201: fetch("GET", MMY01_201_URL),
  getMmy01421: fetch("GET", MMY01_421_URL),
};
