<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					{{t('10299')}}
				</span>
			</p>
			<router-link to="/layers/terms/coupon" class="nav__btn">
				<i class="icon icon-point--white">{{t('10299')}}</i>
			</router-link>
		</div>
	</nav>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name" v-if="isLogin">
							<span>
								<strong>{{userData.nick_nm}}</strong>님의<br>
								사용가능한 쿠폰
							</span><!-- 재번역 체크 -->
						</div>
					</div>
					<div class="mypage__my-point">
						<small>
							My Coupon
						</small>
						<strong v-if="mmy0101.length > 0">{{mmy0101[0].cp_cnt}}</strong><!-- 재번역 체크 -->
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 쿠폰 상세 -->
							<div class="mypage__coupondetail">
								<!-- 쿠폰 등록하기 -->
								<!--
								<button type="button" class="btn btn-couponregister" @click="handleOpenCounponRegister">
									<span>
										<i class="icon icon-ticket--point"></i>
									</span>
									<strong>
										쿠폰 등록하기
									</strong>
									<span>
										<i class="icon icon-rightbig--blur"></i>
									</span>
								</button>
								-->
								<!-- 쿠폰 탭 -->
								<div class="tab tab--half">
									<a :class="{'active': couponTab === 0}" @click="couponTab = 0">{{t('10302')}}</a>
									<a :class="{'active': couponTab === 1}" @click="couponTab = 1">{{t('10303')}}</a>
								</div>
								<!-- 쿠폰들 -->
								<div class="mypage__coupon">
									<div class="coupons">
										<div class="coupon" v-show="couponTab === 0" v-for="(item, index) in mmy010402" :key="index">
											<div class="coupon__info">
												<div class="coupon__category">
													{{item.EV_NM}}
												</div>
												<h3 class="coupon__title" v-if="Number(item.CP_DCRATE) !== 0">
													{{item.CP_DCRATE}}{{t('10304')}}
												</h3>
												<h3 class="coupon__title" v-else>
													{{Number(item.CP_AMT).toLocaleString("en-US")}}{{t('10305')}}
												</h3>
												<div class="coupon__amount">
													{{item.CP_NM}} 최대 {{Number(item.CP_AMT).toLocaleString("en-US")}}원 할인 <!-- 재번역 체크 -->
												</div>
												<div class="coupon__date" v-if="item.EXPIRE_DATE !== ''">
													{{dayjs(item.EXPIRE_DATE).format('YY.MM.DD')}}{{t('10307')}} (D{{dayjs(item.EXPIRE_DATE).diff(dayjs(), 'day') > 0 ? '' : '+'}}{{dayjs(item.EXPIRE_DATE).diff(dayjs(), 'day') * -1}})
												</div>
												<div class="coupon__date" v-else>
													{{t('10308')}}
												</div>
											</div>
											<div class="coupon__check">
												<i class="icon icon-check--white"></i>
											</div>
										</div>
										<div class="coupon coupon--used" v-show="couponTab === 1" v-for="(item, index) in mmy010403" :key="index">
											<div class="coupon__info">
											<div class="coupon__category">
													{{dayjs(item.EXPIRE_DATE).diff(dayjs(), 'day') > 0 ? '사용완료' : '기간만료'}}<!-- 재번역 체크 -->
												</div>
												<h3 class="coupon__title" v-if="Number(item.CP_DCRATE) !== 0">
													{{item.CP_DCRATE}}{{t('10304')}}
												</h3>
												<h3 class="coupon__title" v-else>
													{{Number(item.CP_AMT).toLocaleString("en-US")}}{{t('10305')}}
												</h3>
												<div class="coupon__amount">
													{{item.CP_NM}} 최대 {{Number(item.CP_AMT).toLocaleString("en-US")}}원 할인 <!-- 재번역 체크 -->
												</div>
												<div class="coupon__date">
													{{dayjs(item.EXPIRE_DATE).format('YY.MM.DD')}}{{t('10307')}} (D{{dayjs(item.EXPIRE_DATE).diff(dayjs(), 'day') > 0 ? '' : '+'}}{{dayjs(item.EXPIRE_DATE).diff(dayjs(), 'day') * -1}})
												</div>
											</div>
											<div class="coupon__check">
												<i class="icon icon-check--white"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textbox textbox--margin" v-show="couponTab === 0 ? mmy010402.length <= 0 : mmy010403.length <= 0">
					<div class="textbox__icon">
						<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
					</div>
					<h3 class="textbox__title">{{t('10309')}}</h3>
					<p class="textbox__description">
						{{t('10310')}}
					</p>
				</div>
			</section>
		</div>
	</div>
	<CouponRegister v-if="openCouponRegister" :handleClose="handleCloseCounponRegister" />
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref} from "vue";
import { useRouter } from 'vue-router';
import CouponRegister from "@/components/layers/Coupon_register";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");

export default {
	components: {
		CouponRegister
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy010402 = computed(() => store.state.mmy01.mmy010402.filter(item => item.TYPE !== ""));
		const mmy010403 = computed(() => store.state.mmy01.mmy010403.filter(item => item.TYPE !== ""));
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);

		const openCouponRegister = ref(false);
		const couponTab = ref(0);

		onMounted(()=>{
			if(isLogin.value) {
				store.dispatch("mmy01/fetchMmy010402", {
					proc_cd: "02",
					mem_id: userData.value.mem_token,
				});
				store.dispatch("mmy01/fetchMmy010403", {
					proc_cd: "03",
					mem_id: userData.value.mem_token,
				});
				if(mmy0101.value.length <= 0) {
					store.dispatch("mmy01/fetchMmy0101", {
						proc_cd: "01",
						mem_id: userData.value.mem_token
					});
				}
			}
		});

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈

		return {
			handleOpenCounponRegister() {
				openCouponRegister.value = true;
			},
			handleCloseCounponRegister() {
				openCouponRegister.value = false;
				store.dispatch("mmy01/fetchMmy010402", {
					proc_cd: "02",
					mem_id: userData.value.mem_token,
				});
				store.dispatch("mmy01/fetchMmy0101", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});
			},
			goBack,
			openCouponRegister,
			isLogin,
			userData,
			mmy010402,
			mmy010403,
			mmy0101,
			couponTab,
			dayjs,
			t,
			i18n
		}
	},
}
</script>

