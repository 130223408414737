<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<!-- [D] 세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
				<div class="layer-message__top" style="padding-bottom:36px">
					<h3 class="layer-message__title">
						{{t('10312')}}
					</h3>
					<div class="frms__item">
						<div class="frms__wrap">
							<input type="text" class="ipt-frm" :placeholder="t('10825')" v-model="couponCode">
							<div class="frms__btns" v-if="couponCode.length > 0">
								<button type="button" class="btn-authtel" @click="reset" >
									<i class="icon icon-closesmall">{{t('10036')}}</i>
								</button>
							</div>
						</div>
						<!-- [D] 쿠폰 번호를 잘못 입력 했을때 표시 -->
						<div class="frms__msg" style="text-align:left" v-if="error">
							{{t('10641')}}
						</div>
					</div>
				</div>
				<div class="layer-message__btns">
					<button type="button" class="btn" @click="handleClose">
						{{t('10251')}}
					</button>
					<button type="button" class="btn skyblue" @click="handleSubmit">
						{{t('10337')}}
					</button>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import api from "@/urls/mmy01";
import { useStore } from 'vuex';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ["handleClose"],
	setup(props) {
		const couponCode = ref("");
		const error = ref(false);
		const store = useStore();
		const userData = computed(() => store.state.userData);

		const handleSubmit = async () => {
			const data = await api.getMmy01041({proc_cd: "01", mem_id: userData.value.mem_token, cp_no: couponCode.value});
			if (data.data.Res_cd === "0000") {
				props.handleClose();
			}else {
				error.value = true;
			}
		}
		 const { t }= useI18n() 

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
		return {
			couponCode,
			error,
			t,  //번역필수 모듈
		  i18n, 
			reset: () => {
				couponCode.value = "";
				error.value = false;
			},
			handleSubmit
		};
	},
}
</script>